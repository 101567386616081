import React from "react";
import * as PrivacyPolicyStyles from "./PrivacyPolicyContent.module.scss";
import { Link } from "gatsby";

const PrivacyPolicyContent = () => {
  return (
    <>
      <main className={PrivacyPolicyStyles.PrivacyPolicyWrapper}>
        <div>
          <h1 className="h1">Privacy Policy</h1>
          <section>
            <p className="p es-mb-utility-48-24">
              Effective date: March 24, 2020 endshift (“us", “we", or “our")
              operates the endshift.io website (the “Service"). This page
              informs you of our policies regarding the collection, use, and
              disclosure of personal data when you use our Service and the
              choices you have associated with that data. We use your data to
              provide and improve the Service. By using the Service, you agree
              to the collection and use of information in accordance with this
              policy. Unless otherwise defined in this Privacy Policy, terms
              used in this Privacy Policy have the same meanings as in our Terms
              and Conditions, accessible from{" "}
              <Link className="link" to="/privacy-policy">
                endshift.io/privacy-policy/
              </Link>
            </p>

            <h2 className="h2 es-mb-utility-24-16">
              Information Collection And Use
            </h2>
            <p className="p es-mb-utility-24-16">
              We collect several different types of information for various
              purposes to provide and improve our Service to you.
            </p>

            <h3 className="h3 es-mb-utility-16-8">Personal Data</h3>
            <p className="p es-mb-utility-16-8">
              While using our Service, we may ask you to provide us with certain
              personally identifiable information that can be used to contact or
              identify you (“Personal Data”). Personally, identifiable
              information may include, but is not limited to:
            </p>
            <ul className="ul es-mb-utility-32-24">
              <li>Email address</li>
              <li>First name and last name</li>
              <li>Cookies and Usage Data</li>
            </ul>
            <h3 className="h3 es-mb-utility-16-8">Usage Data</h3>
            <p className="p es-mb-utility-24-16">
              We may also collect information on how the Service is accessed and
              used (“Usage Data”). This Usage Data may include information such
              as your computer’s Internet Protocol address (e.g. IP address),
              browser type, browser version, the pages of our Service that you
              visit, the time and date of your visit, the time spent on those
              pages, unique device identifiers and other diagnostic data.
            </p>
            <h3 className="h3 es-mb-utility-16-8">Tracking & Cookies Data</h3>
            <p className="p es-mb-utility-24-16">
              We use cookies and similar tracking technologies to track the
              activity on our Service and hold certain information.<br></br>{" "}
              <br></br> Cookies are files with a small amount of data which may
              include an anonymous unique identifier. Cookies are sent to your
              browser from a website and stored on your device. Tracking
              technologies also used are beacons, tags, and scripts to collect
              and track information and to improve and analyze our Service. You
              can instruct your browser to refuse all cookies or to indicate
              when a cookie is being sent. However, if you do not accept
              cookies, you may not be able to use some portions of our Service.
            </p>
            <h3 className="h3 es-mb-utility-16-8">Use of Data</h3>
            <p className="p es-mb-utility-16-8">
              endshift uses the collected data for various purposes:
            </p>
            <ul className="ul es-mb-utility-32-24">
              <li className="p">To provide and maintain the Service</li>
              <li className="p">To notify you about changes to our Service</li>
              <li className="p">
                To allow you to participate in interactive features of our
                Service when you choose to do so
              </li>
              <li className="p">To provide customer care and support</li>
              <li className="p">
                To provide analysis or valuable information so that we can
                improve the Service
              </li>
              <li className="p">To monitor the usage of the Service</li>
              <li className="p">
                To detect, prevent and address technical issues
              </li>
            </ul>
            <h3 className="h3 es-mb-utility-16-8">Transfer Of Data</h3>
            <p className="p es-mb-utility-24-16">
              Your information, including Personal Data, may be transferred to —
              and maintained on — computers located outside of your state,
              province, country, or other governmental jurisdiction where the
              data protection laws may differ from those of your jurisdiction.
              If you are located outside Pakistan and choose to provide
              information to us, please note that we transfer the data,
              including Personal Data, to Pakistan and process it there. Your
              consent to this Privacy Policy followed by your submission of such
              information represents your agreement to that transfer. endshift
              will take all steps reasonably necessary to ensure that your data
              is treated securely and in accordance with this Privacy Policy and
              no transfer of your Personal Data will take place to an
              organization or a country unless there are adequate controls in
              place including the security of your data and other personal
              information.
            </p>
            <h3 className="h3 es-mb-utility-16-8">Disclosure Of Data</h3>
            <p className="p es-mb-utility-16-8">
              endshift may disclose your Personal Data in the good faith belief
              that such action is necessary to:
            </p>
            <ul className="ul es-mb-utility-24-16">
              <li className="p">To comply with a legal obligation.</li>
              <li className="p">
                To protect and defend the rights or property of endshift.
              </li>
              <li className="p">
                To prevent or investigate possible wrongdoing in connection with
                the Service.
              </li>
              <li className="p">
                To protect the personal safety of users of the Service or the
                public.
              </li>
              <li className="p">To protect against legal liability.</li>
            </ul>
            <h3 className="h3 es-mb-utility-16-8">Security Of Data</h3>
            <p className="p es-mb-utility-24-16">
              The security of your data is important to us, but remember that no
              method of transmission over the Internet, or method of electronic
              storage is 100% secure. While we strive to use commercially
              acceptable means to protect your Personal Data, we cannot
              guarantee its absolute security.
            </p>
            <h3 className="h3 es-mb-utility-16-8">Service Providers</h3>
            <p className="p es-mb-utility-24-16">
              We may employ third-party companies and individuals to facilitate
              our Service (“Service Providers”), to provide the Service on our
              behalf, perform Service-related services, or assist us in
              analyzing how our Service is used. These third parties have access
              to your Personal Data only to perform these tasks on our behalf
              and are obligated not to disclose or use it for any other purpose.
            </p>
            <h3 className="h3 es-mb-utility-16-8">Analytics</h3>
            <p className="p es-mb-utility-16-8">
              We may use third-party Service Providers to monitor and analyze
              the use of our Service.
            </p>
            <h4 className="h3 es-mb-utility-16-8">Google Analytics</h4>
            <p className="p es-mb-utility-24-16">
              Google Analytics is a web analytics service offered by Google that
              tracks and reports website traffic. Google uses the data collected
              to track and monitor the use of our Service. This data is shared
              with other Google services. Google may use the collected data to
              contextualize and personalize the ads of its own advertising
              network. You can opt out of having made your activity on the
              Service available to Google Analytics by installing the Google
              Analytics opt-out browser add-on. The add-on prevents Google
              Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing
              information with Google Analytics about visits activity. For more
              information on the privacy practices of Google, please visit the
              Google Privacy & Terms.
            </p>
            <h3 className="h3 es-mb-utility-16-8">Links To Other Sites</h3>
            <p className="p es-mb-utility-24-16">
              Our Service may contain links to other sites that are not operated
              by us. If you click on a third-party link, you will be directed to
              that third party’s site. We strongly advise you to review the
              Privacy Policy of every site you visit. We have no control over
              and assume no responsibility for the content, privacy policies, or
              practices of any third-party sites or services.
            </p>
            <h3 className="h3 es-mb-utility-16-8">Children’s Privacy</h3>
            <p className="p es-mb-utility-24-16">
              Our Service does not address anyone under the age of 18
              (“Children”). We do not knowingly collect personally identifiable
              information from anyone under the age of 18. If you are a parent
              or guardian and you are aware that your Children have provided us
              with Personal Data, please contact us. If we become aware that we
              have collected Personal Data from children without verification of
              parental consent, we take steps to remove that information from
              our servers.
            </p>
            <h3 className="h3 es-mb-utility-16-8">
              Changes To This Privacy Policy
            </h3>
            <p className="p es-mb-utility-24-16">
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
              We will let you know via email and/or a prominent notice on our
              Service, prior to the change becoming effective and update the
              “effective date” at the top of this Privacy Policy. You are
              advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>
            <h3 className="h3 es-mb-utility-16-8">Contact Us</h3>
            <p className="p es-mb-utility-24-16">
              If you have any questions about this Privacy Policy, please
              contact us: -
            </p>
            <ul className="ul">
              <li className="p">
                By email:{" "}
                <Link className="link" href="mailto:info@endshift.io">
                  info@endshift.io
                </Link>
              </li>
              <li className="p">
                By visiting this page on our website:{" "}
                <Link className="link" to="/privacy-policy">
                  http://endshift.io/privacy-policy/
                </Link>
              </li>
            </ul>
          </section>
        </div>
      </main>
    </>
  );
};

export default PrivacyPolicyContent;
